/* Import Custom Fonts */
@font-face {
  font-family: "Cloud2";
  src: url("./Style/Cloud/Cloud2.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RightGrotesk";
  src: url("./Style/RightGrotesk/RightGrotesk-CompactBlack.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DynaPuff";
  src: url("./Style/DynaPuff/static/DynaPuff-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DynaPuff2";
  src: url("./Style/DynaPuff/static/DynaPuff-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrollbars */
}

.background {
  background-image: url("./arts/backgroundcat.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  width: 100%;
}

.hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 10px 20px;
  margin-top: 0; /* No margin at the top */
  min-height: 100vh;
}

/* Title and Description */
.hero-title {
  font-size: 3rem;
  font-family: "DynaPuff", cursive;
  color: #fff4e2;
  margin: 0;
  top: -100px;
  z-index: 15;
}

.hero-description {
  font-size: 1.25rem;
  font-family: "DynaPuff", sans-serif;
  color: #333;
  top: -50px;
  margin-top: 0px; /* Space between title and description */
  text-align: center;
  max-width: 80%; /* Optional for responsive design */
  line-height: 1.5;
}

.hero-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

/* Images Around Hero Section */
.hero-image-left {
  position: absolute;
  top: 10%; /* Adjust based on design preference */
  left: 10%; /* Adjust based on design preference */
  max-width: 240px;
  height: auto;
  z-index: 0;
}

.hero-image-right-top {
  position: absolute;
  top: 10%;
  right: 0%;
  max-width: 300px;
  height: auto;
}

.hero-image-right-bottom {
  position: absolute;
  bottom: -80px;
  left: -140px;
  max-width: 680px;
  z-index: 100;
  height: auto;
}

.hero-image-right {
  position: absolute;
  bottom: 70%;
  right: 6%;
  max-width: 360px;
  height: auto;
}

/* Section Styling */
.section {
  font-family: "DynaPuff", sans-serif;
  padding: 50px 20px;
  text-align: center;
  display: flex;

  flex-direction: column;

  align-self: center;
  align-items: center;
}

.section2 {
  position: relative;

  font-family: "DynaPuff", sans-serif;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 700px;
}

.section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: "DynaPuff", sans-serif;
}

.section2 h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: "DynaPuff", sans-serif;
}

.section p {
  font-size: 1.25rem;
  color: #333;
  font-family: "DynaPuff2", sans-serif;
}

.section2 p {
  font-size: 1.25rem;
  color: #333;
  font-family: "DynaPuff2", sans-serif;
}

.logo {
  max-width: 240px; /* Adjust size to fit your design */
  height: auto; /* Maintain aspect ratio */
  margin: 20px 0; /* Space around the logo */
}
.logoTop {
  max-width: 100px; /* Adjust size to fit your design */
  left: "10px";
}

/* Buttons Styling */
.menu-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

.menu-container a {
  text-decoration: none;
}

.menu-container button {
  font-family: "DynaPuff", sans-serif; /* Apply specific font to buttons */
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #5e8e3e; /* Button color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-container button:hover {
  background-color: #417a29; /* Darker green on hover */
}

/* Image Styling */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Custom Section Styling */
.section {
  padding: 50px 20px;
  text-align: center;
}

@keyframes wobble {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -8px);
  }
  50% {
    transform: translate(-10px, 5px);
  }
  75% {
    transform: translate(10px, -5px);
  }
}
@keyframes wobble1 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-10px, +5px);
  }
  50% {
    transform: translate(6px, -5px);
  }
  75% {
    transform: translate(-10px, +5px);
  }
}
@keyframes upDown {
  0%,
  100% {
    bottom: 10px;
  }
  50% {
    bottom: 30px;
  }
}
@keyframes wobbleRotate {
  0%,
  100% {
    transform: rotate(-5deg); /* Tilt left */
  }
  50% {
    transform: rotate(5deg); /* Tilt right */
  }
}

.wobbleRot {
  display: block; /* Ensure the element is suitable for transformation */
  animation: wobbleRotate 9s ease-in-out infinite;
}
.updown {
  animation: upDown 3s ease-in-out infinite;
}
.wobble-effect {
  animation: wobble 8s ease-in-out infinite;
}
.wobble-effect1 {
  animation: wobble1 8s ease-in-out infinite;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hero-title {
    font-size: 1.5rem; /* Reduce font size for tablets */
    line-height: 1.2; /* Optional: Adjust line height for better spacing */
  }
  .hero-image-left {
    top: 24%; /* Adjust based on design preference */
    left: 10%; /* Adjust based on design preference */
    max-width: 120px;
    height: auto;
  }
  .hero-image-right-top,
  .hero-image-right-bottom,
  .hero-image-right {
    bottom: 99%;
    right: 3%;
    max-width: 120px;
    height: auto;
  }
  .hero-logo-left-top {
    bottom: 99%;
    right: 6%;
    width: 120px;
    height: auto;
  }
  .hero-title {
    font-size: 2rem; /* Reduce font size for smaller screens */
  }

  .hero-description {
    font-size: 1rem; /* Adjust description font size */
  }

  .section {
    padding: 30px 10px; /* Reduce padding for better spacing */
  }
}

/* Extra Small Screens */
@media (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem; /* Further reduce font size for phones */
    line-height: 1.1; /* Optional: Further line height adjustment */
  }
  .hero-image-left,
  .hero-image-right-top,
  .hero-image-right-bottom,
  .hero-image-right {
    max-width: 100px; /* Further reduce size */
  }

  .logo {
    max-width: 150px; /* Adjust logo size */
  }

  .hero-title {
    font-size: 1.5rem; /* Further reduce font size */
  }

  .hero-description {
    font-size: 0.9rem; /* Adjust description font size */
  }

  .section {
    padding: 20px 10px; /* Further reduce padding */
  }
}
