body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@keyframes wobble {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -8px);
  }
  50% {
    transform: translate(-10px, 5px);
  }
  75% {
    transform: translate(10px, -5px);
  }
}
@keyframes wobble1 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-10px, +5px);
  }
  50% {
    transform: translate(6px, -5px);
  }
  75% {
    transform: translate(-10px, +5px);
  }
}
@keyframes upDown {
  0%,
  100% {
    bottom: 10px;
  }
  50% {
    bottom: 30px;
  }
}
.cloud-text-radial {
  font-size: 48px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback for non-webkit browsers */
}
.updown {
  animation: upDown 3s ease-in-out infinite;
}
.wobble-effect {
  animation: wobble 8s ease-in-out infinite;
}
.wobble-effect {
  animation: wobble 8s ease-in-out infinite;
}
.wobble-effect1 {
  animation: wobble1 8s ease-in-out infinite;
}
